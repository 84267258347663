<template>
  <a-drawer
    title="权限设置"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 0 }"
      :wrapper-col="{ span: 24 }"
    >
      <!-- 用户名 -->
      <a-form-item
        name="role"
      >
        <a-radio-group v-model:value="formState.role_type" name="radioGroup" @change="changeRole">
          <a-radio value="1">管理员</a-radio>
          <a-radio value="2">成员</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 菜单 -->
      <a-form-item
        name="menus"
      >
        <template v-for="first in formState.menus">
          <!-- 一级菜单 -->
          <div class="first-menu">
            <div class="first-menu-title">{{ first.show_name }}</div>
            <div class="first-menu-action">
              <a class="link" @click="selectAll(first.route)">全选</a>
              <a class="link" @click="selectReverse(first.route)">反选</a>
            </div>
          </div>
          <!-- 二级菜单 -->
          <div class="second-menu">
            <a-form-item v-for="item in first.menu">
              <a-checkbox
                v-model:checked="item.select"
                :disabled="formState.role_type === '1'"
              >
                {{ item.show_name }}
              </a-checkbox>
            </a-form-item>
          </div>
        </template>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { userMenuList, userMenuSave } from '@/api/request'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 表单
let formState = reactive({
  // 角色
  role_type: '2',
  // 菜单
  menus: []
})
// 复制一份初始详情数据
let copyData = []

// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // id
  id.value = params.id
  // 获取详情数据
  getDetail()
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    visible.value = false
    copyData = {}
  }
}

// 获取详情
function getDetail () {
  isLoading.value = true
  const params = {
    user_id: id.value,
    guard_name: 'project',
    project_id: Pub.CUSTOM('project'),
  }
  userMenuList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 管理中心菜单及选中情况
      let manegeData = {}
      // 是否存在账户权限
      if (data.user_menus && data.user_menus.length) {
        // 账户下已有权限
        manegeData = data.user_menus[0]
      } else {
        // 新账户
        manegeData = {
          // 填入默认菜单
          menus: data.defult_menus,
          // 角色默认为成员
          role_type: '2'
        }
      }
      // 将二级菜单选中状态的值改为布尔值
      manegeData.menus.forEach(item => {
        item.menu.forEach(i => {
          i.select = !!i.select
        })
      })
      // 如果是成员身份，复制一份数据出来
      if (manegeData.role_type === '2') {
        // 这里赋值的时候要深拷贝，否则会联动
        copyData = Pub.deepClone(manegeData)
      }
      // 表单数据
      nextTick(() => {
        formState.role_type = manegeData.role_type
        formState.menus = manegeData.menus
      })
    } else {
      message.error(res.message || msg)
    }
  })
}


// 切换角色
function changeRole (e) {
  const value = e.target.value
  // 管理员
  if (value === '1') {
    // 手动选中所有二级菜单
    formState.menus.forEach(first => {
      first.menu.forEach(item => {
        item.select = true
      })
    })
  } else {
    // 切换到成员
    if (copyData.role_type) {
      // 如果有拷贝数据，说明已有成员权限数据，直接反显
      formState.menus = Pub.deepClone(copyData.menus)
    } else {
      // 无拷贝数据
      formState.menus.forEach(first => {
        first.menu.forEach(item => {
          item.select = false
        })
      })
    }
  }
}

// 全选
function selectAll (route) {
  formState.menus.forEach(first => {
    if (first.route === route) {
      first.menu.forEach(item => {
        item.select = true
      })
    }
  })
}

// 反选
function selectReverse (route) {
  formState.menus.forEach(first => {
    if (first.route === route) {
      first.menu.forEach(item => {
        item.select = !item.select
      })
    }
  })
}

// 提交
function touchSubmit () {
  isLoading.value = true
  // 处理二级菜单的选中
  let ids = []
  formState.menus.forEach(item => {
    // 二级菜单选中计数器
    let num = 0
    item.menu.forEach(i => {
      if (i.select) {
        ids.push(i.id)
        num += 1
      }
    })
    // 若二级菜单下有选中，一级菜单id也要拼接进去
    if (num > 0) {
      ids.push(item.id)
    }
  })
  // 参数准备
  const params = {
    // 用户
    user_id: id.value,
    // 来源：1管理中心-管理中心权限  2管理中心-项目权限 3运营中心-权限
    type: 3,
    // 权限
    role_permission: [
      {
        // 项目id
        project_id: Pub.CUSTOM('project'),
        // 角色
        role_type: formState.role_type,
        // 二级菜单选中id
        permission_ids: ids
      }
    ]
  }
  userMenuSave(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('操作成功')
      onClose()
      // 更新父组件
      emit('success')
    } else {
      message.error(res.message || msg)
    }
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.first-menu {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  padding: 0 16px;
  .link{
    color: #bbb;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
.second-menu {
  display: flex;
  padding: 16px 0 0 16px;
  flex-wrap: wrap;
  .ant-form-item {
    margin-right: 14px;
  }
}
</style>